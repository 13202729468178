import { Status } from "src/api/Status";
import { Active } from "src/api/Active";

// Utility functions for user status.
export const StatusUtil = {

    isStatusActive(role: Status): boolean {
        return role === Active || role === 'active';
    },
}
