// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsStatus = exports;

import * as nsActive from './Active';
import * as nsDisabled from './Disabled';
import * as nsSuspended from './Suspended';

export const dependencyModules = [
  nsActive,
  nsDisabled,
  nsSuspended,
];

export interface StatusUnion {
}

export function isStatusUnion(v: any): v is StatusUnion {
  return (
    typeof v === 'object' && Object.keys(v).length === 0
  );
}

export class StatusSingleton {
  public readonly Active: nsActive.ActiveSingleton = nsActive.ActiveInhabitant;

  public readonly Disabled: nsDisabled.DisabledSingleton = nsDisabled.DisabledInhabitant;

  public readonly Suspended: nsSuspended.SuspendedSingleton = nsSuspended.SuspendedInhabitant;

  private static instance: StatusSingleton;

  private constructor() {}

  public static getInstance() {
    if (!StatusSingleton.instance) {
      StatusSingleton.instance = new StatusSingleton();
    }

    return StatusSingleton.instance;
  }
}

// export const StatusSingletonInhabitant: StatusSingleton = StatusSingleton.getInstance();

// export function isStatusSingleton(v: any): v is StatusSingleton {
//   return (v instanceof StatusSingleton) && (v === StatusSingletonInhabitant);
// }

// export const StatusInhabitant = StatusSingletonInhabitant;

export type Status = StatusUnion;

export function isStatus(v: any): v is Status {
  return (
    isStatusUnion(v)
  );
}