import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Avatar,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import { ViewIcon, ViewOffIcon, LockIcon, UnlockIcon } from '@chakra-ui/icons';
import ErrorMessage from 'src/components/ErrorMessage';
import { authService } from 'src/service/AuthService';
import { useAuthContext } from 'src/auth/AuthContext';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { userService } from 'src/service/UserService';

const Profile = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const editor = useRef(null); // avatar editor

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | File>('');

  const navigate = useNavigate();
  const { user } = useAuthContext();

  useEffect(() => {
    const url = userService.avatarUrl(user);
    console.log('avatar url = ' + url);
    setAvatarUrl(url);
  }, [user]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      switch (e.code) {
        case 'Enter':
          handlePasswordSubmit(e)
          break;
      }
    };
    document.addEventListener('keydown', onKeyDown, true);
    return () => document.removeEventListener('keydown', onKeyDown, true);
  });

  const handlePasswordSubmit = async event => {
    console.info('changing password...');
    event.preventDefault();
    try {
      setIsSubmitting(true);
      if (!oldPassword) {
        throw new Error('Aktuelles passwort leer');
      }
      if (!newPassword) {
        throw new Error('Naues passwort leer');
      }
      if (!confirmNewPassword) {
        throw new Error('Naues passwort wiederholen leer');
      }
      if (newPassword !== confirmNewPassword) {
        throw new Error('Die beiden Passworte sind nicht identisch');
      }
      await authService.passwordChange(oldPassword, newPassword);
      setIsSubmitting(false);
      setSuccess(true);
      console.info(`password changed...`);
    } catch (error) {
      console.error(error);
      setError(error.message);
      setIsSubmitting(false);
      // setOldPassword('');
      // setNewPassword('');
      // setConfirmNewPassword('');
    }
  };

  const handleAvatarSubmit = async event => {
    console.info('changing avatar...');
    event.preventDefault();
    try {
      setIsSubmitting(true);
      editor.current.getImageScaledToCanvas().toBlob((blob) => {
        // upload blob to server
        userService.avatarUpload(blob)
        setIsSubmitting(false);
        setSuccess(true);
        console.info('avatar uploaded');
      }, 'image/jpeg');
    } catch (error) {
      console.error(error);
      setError(error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <Flex
      minH={{ base: '50vh', lg: '70vh' }}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.25', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'2xl'} py={6} px={6}>
        <Stack align={'center'}>
          <Avatar bg="teal.500" />
          <Heading fontSize={'4xl'} textAlign={'center'}>{user?.data?.firstName} {user?.data?.lastName}</Heading>
          {error && <ErrorMessage message={error} />}
          {success && <Alert status='success'><AlertIcon />Eingestellt!</Alert>}
        </Stack>

        {/* 2 col layout */}
        <Stack direction={{ base: 'column', sm: 'row' }} spacing={8}>

          {/* 1st col */}
          <Box
            rounded={'lg'}
            bg={useColorModeValue('gray.25', 'gray.700')}
            boxShadow={'lg'}
            pos='relative'
            p={8}
            display="flex"
            flexDirection="column"
            height="100%"
            flex="1"
            minHeight="480px"
          >
            <Text align='center' fontSize={'lg'} color={'gray.600'}>Bild ändern 👤</Text>
            <Box flex="1" display="flex" justifyContent="center" alignItems="center">
              <Dropzone
                onDrop={(dropped) => {
                  setAvatarUrl(dropped[0]);
                }}
                // noClick
                noKeyboard
                // style={{ width: '250px', height: '250px' }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <AvatarEditor
                      image={avatarUrl}
                      ref={editor}
                      width={200}
                      height={200}
                      border={10}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={1.2}
                      rotate={0}
                      borderRadius={100}
                      onLoadFailure={(ev) => {
                        console.log('avatar onLoadFailure', ev);
                        setAvatarUrl('/images/matterhorn-256.webp');
                      }}
                    />
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </Box>
            <Stack spacing={10} pt={2} direction={{ base: 'column', sm: 'row' }} justify={'flex-end'} mt='auto'
            >
              {!success && <Button isLoading={isSubmitting} bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }} onClick={event => handleAvatarSubmit(event)}>
                Bild ändern
              </Button>}
            </Stack>
          </Box>

          {/* 2nd col */}
          <Box
            rounded={'lg'}
            bg={useColorModeValue('gray.25', 'gray.700')}
            boxShadow={'lg'}
            pos='relative'
            p={8}
            display="flex"
            flexDirection="column"
            height="100%"
            flex="1"
            minHeight="480px"
          >
            <Stack spacing={4} flex="1" display="flex">
              <Text align='center' fontSize={'lg'} color={'gray.600'}>Passwort ändern 🔐</Text>
              <FormControl id="oldPassword" isRequired>
                <FormLabel>Aktuelles Passwort</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none' children={<UnlockIcon color='gray.400' />} />
                  <Input value={oldPassword} type={showPassword ? 'text' : 'password'} title='' onChange={event => {
                    setOldPassword(event.currentTarget.value);
                    setError('');
                  }
                  } />
                  <InputRightElement h={'full'}>
                    <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl id="newPassword" isRequired>
                <FormLabel>Neues Passwort</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none' children={<LockIcon color='gray.400' />} />
                  <Input value={newPassword} type={showPassword ? 'text' : 'password'} title='' onChange={event => {
                    setNewPassword(event.currentTarget.value);
                    setError('');
                  }
                  } />
                  <InputRightElement h={'full'}>
                    <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <PasswordStrengthBar password={newPassword} shortScoreWord='zu kurz' scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']} />
              </FormControl>
              <FormControl id="confirmNewPassword" isRequired>
                <FormLabel>Neues Passwort wiederholen</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none' children={<LockIcon color='gray.400' />} />
                  <Input value={confirmNewPassword} type={showPassword ? 'text' : 'password'} title='' onChange={event => {
                    setConfirmNewPassword(event.currentTarget.value);
                    setError('');
                  }
                  } />
                  <InputRightElement h={'full'}>
                    <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10} pt={2} direction={{ base: 'column', sm: 'row' }} justify={'flex-end'} mt='auto'>
                {!success && <Button isLoading={isSubmitting} bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }} onClick={event => handlePasswordSubmit(event)}>
                  Passwort ändern
                </Button>}
              </Stack>
            </Stack>
          </Box>

        </Stack>

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          align={'center'}
          justify={'flex-end'}
          spacing={10}>
          <Button onClick={() => navigate('/')} bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }}>
            Schliessen
          </Button>
        </Stack>

      </Stack>
    </Flex>
  );
}

export default Profile