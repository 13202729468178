import { useAuthContext } from 'src/auth/AuthContext';
import Hero from '../components/Hero'
import { Center, Divider, Image, Skeleton, Spinner, Stack, Text } from '@chakra-ui/react';
import Features from 'src/components/Features';
import Footer from 'src/components/Footer';
import Content from 'src/components/Content';
import ProjectTimeline from 'src/components/ProjectTimeline';
import { projectService } from 'src/service/ProjectService';
import { useEffect, useState } from 'react';
import { ProjectsActiveListResponse } from 'src/api/ProjectsActiveListResponse';
import { isWinterHoliday } from 'src/data/DateUtil';
import HorizontalTimeline from 'src/components/HorizontalTimeline';
import ExchangeRates from 'src/components/ExchangeRates';

// logged in landing page
const Dashboard = ({ firstName }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState<ProjectsActiveListResponse | null>(null);
    const [timelineHeight, setTimelineHeight] = useState(700);

    useEffect(() => {
        setIsLoading(true);
        projectService
            .listActives()
            .then(r => {
                setProjects(r);
                // set the height of the project timeline based on the amount of active projects
                if (r.results.length <= 6) {
                    setTimelineHeight(300);
                } else {
                    setTimelineHeight(700);
                }
            })
            .finally(() => setIsLoading(false));

    }, []);

    return (
        <Content>
            {isWinterHoliday(new Date()) && <Image boxSize='100%' objectFit='cover' src='/images/holidays/new-year.webp' alt='Christmas' position='relative' top='-39px' />}
            <Text fontSize={{ base: '1xl', lg: `2xl` }}>Schön dich zu sehen, <b>{firstName}</b> 👋</Text>
            {isLoading ? (
                <Stack py={5}>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                    {Array(15).fill(0).map((_, ix) => (
                        <Skeleton key={`sk-${ix}`} height='2rem' />
                    ))}
                </Stack>
            ) : (
                <>
                    <ProjectTimeline
                        projects={projects?.results.concat() ?? []}
                        countries={projects?.countries.concat() ?? []}
                        latestEndDate={projects?.endLatestDate}
                        height={timelineHeight} />
                    <HorizontalTimeline events={projects?.budgets.concat() ?? []} />
                </>
            )}
            <Divider mt='4rem' mb='1rem'></Divider>
            <Text fontSize='lg'>Indikative Wechselkurse</Text>
            <Center>
                <ExchangeRates base={"CHF"} all={["USD", "BDT", "KES"]} />
            </Center>
            <Footer />
        </Content>
    )
}

// logged out landing page
const LoggedOut = () => {
    return (
        <>
            {isWinterHoliday(new Date()) && <Image boxSize='100%' objectFit='cover' src='/images/holidays/new-year.webp' alt='Christmas' position='relative' top='-18px' />}
            <Hero />
            <Features />
            <Footer />
        </>
    );
}

const Landing = () => {
    const { user } = useAuthContext();

    let comp = user ?
        <Dashboard firstName={user.data.firstName} />
        :
        <LoggedOut />;
    return comp;
}

export default Landing