// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsActive = exports;

import * as nsStatus from './Status';
import type { Status } from './Status';

export const dependencyModules = [
  nsStatus,
];

export class Active implements Status {
  private static instance: Active;

  private constructor() {}

  public static getInstance() {
    if (!Active.instance) {
      Active.instance = new Active();
    }

    return Active.instance;
  }
}

export const ActiveInhabitant: Active = Active.getInstance();

export function isActive(v: any): v is Active {
  return (v instanceof Active) && (v === ActiveInhabitant);
}

export type ActiveSingleton = Active;
