
import { Badge } from "@chakra-ui/react"
import { Status } from "src/api/Status";
import { StatusUtil } from "src/data/StatusUtil";

interface StatusBadgeProps {
    status: Status
}

const StatusBadge = (props: StatusBadgeProps) => {
    return (
        <>
            {StatusUtil.isStatusActive(props.status) && (<Badge variant='solid' colorScheme='green'>Activ</Badge>)}
            {!StatusUtil.isStatusActive(props.status) && (<Badge variant='solid' colorScheme='red'>Deaktiviert</Badge>)}
        </>
    )
}

export default StatusBadge