import { Badge, Text, Table, Tbody, Td, Tr, Box, Tooltip, Stack, Link, HStack } from "@chakra-ui/react"
import { useState, useEffect } from "react";
import { InfoResponse } from "../api/InfoResponse";
import { infoService } from '../service/InfoService';
import { parseIso } from "src/data/DateUtil";
import { Duration, differenceInSeconds, formatDuration, intervalToDuration, setDefaultOptions } from "date-fns";
import { de } from 'date-fns/locale';
import CountryFlag from "src/components/CountryFlag";
import packageJson from '../../package.json';
import apiClient from "src/service/core/ApiClient";
import ColorPicker from "src/components/ColorPicker";
import { OrgUtil } from "src/data/OrgUtil";
import { ProgressBarReact } from "src/components/ProgressBar";
import { useAuthContext } from "src/auth/AuthContext";
import RoleBadge from "src/components/RoleBadge";
import ExchangeRates from "src/components/ExchangeRates";

const Info = () => {
    const { user } = useAuthContext();
    const [buildTime, setBuildTime] = useState<Date | null>(null);
    const [serverTime, setServerTime] = useState<Date | null>(null);
    const [beUpTime, setBeUpTime] = useState<Duration>({ seconds: 0 });
    const [dbAlive, setDbAlive] = useState<boolean>(false);
    const [s3Objects, setS3Objects] = useState<number>(-1);
    const [memoryPercentile, setMemoryPercentile] = useState<number>(0);
    //const [feUpTime] = useState<Duration>({ seconds: 0 });

    setDefaultOptions({ locale: de });

    useEffect(() => {
        infoService.getInfo().then((res: InfoResponse) => {
            setBuildTime(parseIso(res.buildTime));
            let serverNow = parseIso(res.currentTime);
            setServerTime(serverNow);
            let seconds = differenceInSeconds(serverNow, parseIso(res.startTime))
            const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
            setBeUpTime(duration);
            setDbAlive(res.dbAlive);
            setS3Objects(res.s3Objects);
            setMemoryPercentile(res.memoryUsedPercentile);

            // !!! on live runs with Caddy, on local runs from Node
            // let clientNow = new Date()
            // let feUptime = differenceInSeconds(clientNow, feStartTime)
            // console.info(`FE uptime ${feUptime} - now:${clientNow} start:${feStartTime}`);
            // let feDuration = intervalToDuration({ start: 0, end: feUptime * 1000 })
            // setFeUpTime(feDuration);
        });

    }, []);

    return (
        <>
            <Box p='.5rem'>
                <Table variant='striped' colorScheme='teal' size='sm' align={'center'}>
                    <Tbody>
                        <Tr>
                            <Td width='25%'><Text as='b'>User:</Text></Td>
                            <Td>
                                {user && (
                                    <HStack ml='5'>
                                        <Text fontWeight='bold'>{user.data.firstName}</Text>
                                        <RoleBadge role={user.data.role}/>
                                    </HStack>
                                )}
                                {!user && (
                                    <HStack ml='5'>
                                        <Badge variant='outline' colorScheme='red'>None</Badge>
                                    </HStack>
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Organization:</Text></Td>
                            <Td>
                                <Badge ml='5' mr='10' variant='outline' colorScheme={OrgUtil.isDemo() ? 'green' : 'yellow'}>{OrgUtil.isDemo() ? 'Demo' : 'Co-operaid'}</Badge>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Status:</Text></Td>
                            <Td>
                                <Badge ml='5' mr={10} variant='outline' colorScheme={serverTime ? 'green' : 'red'}>{serverTime ? 'Pong' : 'Ping'}</Badge>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Database:</Text></Td>
                            <Td>
                                <Badge ml='5' mr={10} variant='outline' colorScheme={dbAlive ? 'green' : 'red'}>{dbAlive ? 'Alive' : 'Checking'}</Badge>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>S3 Objects:</Text></Td>
                            <Td><Text ml='5' as='i'>{s3Objects}</Text></Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Build Zeit:</Text></Td>
                            <Td><Text ml='5' as='i'>{buildTime?.toLocaleString() ?? '?'}</Text></Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Server Zeit:</Text></Td>
                            <Td>
                                <Text ml='5' as='i'>{serverTime?.toLocaleString() ?? '?'}</Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Frontend Version:</Text></Td>
                            <Td>
                                <Text ml='5' as='i'>{packageJson.version}</Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>API Host:</Text></Td>
                            <Td>
                                <Text ml='5' as='i'>{apiClient.baseUrl}</Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Hostname:</Text></Td>
                            <Td>
                                <Text ml='5' as='i'>{window.location.hostname}</Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Lauf Zeit Backend:</Text></Td>
                            <Td>
                                <Text ml='5' as='i'>{formatDuration(beUpTime, { format: ['days', 'hours', 'minutes', 'seconds'] })}</Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Lauf Zeit Frontend:</Text></Td>
                            <Td>
                                <Text ml='5' as='i'>{formatDuration(beUpTime, { format: ['days', 'hours', 'minutes', 'seconds'] })}</Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Memory Usage:</Text></Td>
                            <Td>
                                <Box ml='5' w='12rem'>{ProgressBarReact(memoryPercentile / 100)}</Box>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>OpenAPI:</Text></Td>
                            <Td>
                                <Box ml={5}>
                                    <Text color='blue.600'>
                                        <Link onClick={_ => window.location.href = '/docs'}>/docs</Link>
                                    </Text>
                                </Box>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td><Text as='b'>Entwicklung:</Text></Td>
                            <Td>
                                <Box ml={5}>
                                    <Text color='blue.600'>
                                        <Link onClick={_ => window.location.href = '/dev'}>/dev</Link>
                                    </Text>
                                </Box>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
                <ExchangeRates base={"CHF"} all={["USD", "BDT", "KES"]} />
                <HStack mt='1rem'>
                    <Text>Test Color Picker</Text>
                    <ColorPicker value='#00AA00' onChange={(_) => { }} isReadOnly={false} />
                </HStack>
                <Stack alignItems={{ base: 'flex-end' }}>
                    <Tooltip label='Switzerland'>
                        <span><CountryFlag code='CH' /></span>
                    </Tooltip>
                </Stack>
            </Box>
        </>
    )
}

export default Info