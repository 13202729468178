// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsSuspended = exports;

import * as nsStatus from './Status';
import type { Status } from './Status';

export const dependencyModules = [
  nsStatus,
];

export class Suspended implements Status {
  private static instance: Suspended;

  private constructor() {}

  public static getInstance() {
    if (!Suspended.instance) {
      Suspended.instance = new Suspended();
    }

    return Suspended.instance;
  }
}

export const SuspendedInhabitant: Suspended = Suspended.getInstance();

export function isSuspended(v: any): v is Suspended {
  return (v instanceof Suspended) && (v === SuspendedInhabitant);
}

export type SuspendedSingleton = Suspended;
