// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsDisabled = exports;

import * as nsStatus from './Status';
import type { Status } from './Status';

export const dependencyModules = [
  nsStatus,
];

export class Disabled implements Status {
  private static instance: Disabled;

  private constructor() {}

  public static getInstance() {
    if (!Disabled.instance) {
      Disabled.instance = new Disabled();
    }

    return Disabled.instance;
  }
}

export const DisabledInhabitant: Disabled = Disabled.getInstance();

export function isDisabled(v: any): v is Disabled {
  return (v instanceof Disabled) && (v === DisabledInhabitant);
}

export type DisabledSingleton = Disabled;
