import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Image,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Avatar,
  Switch,
  Show,
  Link,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon, EmailIcon, LockIcon } from '@chakra-ui/icons';
import ErrorMessage from 'src/components/ErrorMessage';
import { useAuthContext } from 'src/auth/AuthContext';
import { authService } from 'src/service/AuthService';
import { userService } from 'src/service/UserService';
import WebUser from 'src/auth/WebUser';
import { OrgUtil } from 'src/data/OrgUtil';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { login, logout } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const initialFocusRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      switch (e.code) {
        case 'Enter':
          handleSubmit(e)
          break;
      }
    };
    document.addEventListener('keydown', onKeyDown, true);
    return () => document.removeEventListener('keydown', onKeyDown, true);
  });

  const handleSubmit = async event => {
    console.info('logging in...');
    event.preventDefault();
    try {
      setIsLoading(true);
      if (!email) {
        throw new Error('E-Mail leer');
      }
      if (!password) {
        throw new Error('Passwort leer');
      }

      let loginResponse = await authService.login(email, password, rememberMe);
      let meResponse = await userService.getMe(loginResponse.token);
      let webUser: WebUser = {
        token: loginResponse.token,
        data: meResponse
      };
      // mark as logged in
      login(webUser);
      setIsLoading(false);
      console.info(`${webUser.data.firstName} succesfully logged in...`);
      // go to the landing page
      navigate('/');
    } catch (error) {
      console.error(error.message);
      // in case of errors, logout
      logout();
      if (error.message?.includes('leer')) {
        setError(error.message);
      } else if (error.message === 'Failed to fetch') {
        setError('Verbindungsproblem');
      } else {
        setError('Ungültiger E-Mail oder Passwort');
        setEmail('');
        setPassword('');
      }
      setIsLoading(false);
    }
  };

  return (
    <Stack as={Box} direction={{ base: 'column', md: 'row' }}
      align={'center'}
      justify={{ base: 'left', lg: 'center' }}
    >

      <Flex
        minH={{ base: '50vh', lg: '70vh' }}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.25', 'gray.800')}>
        <Stack spacing={2} mx={'auto'} maxW={'lg'}>
          <Stack align={'center'}>
            <Avatar bg="teal.500" />
            <Heading fontSize={'4xl'} textAlign={'center'}>Anmeldung</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>Willkommen zurück 🎉</Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('gray.25', 'gray.700')}
            boxShadow={'lg'}
            pos='relative'
            p={8}>
            <Stack spacing={4}>
              {error && <ErrorMessage message={error} />}
              <FormControl id='email' isRequired>
                <FormLabel>E-Mail</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none' children={<EmailIcon color='gray.400' />} />
                  <Input autoFocus={true} value={email} type="email" title=''
                    ref={initialFocusRef}
                    onChange={event => {
                      setEmail(event.currentTarget.value);
                      setError('');
                    }} />
                </InputGroup>
              </FormControl>
              <FormControl id='password' isRequired>
                <FormLabel>Passwort</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none' children={<LockIcon color='gray.400' />} />
                  <Input value={password} type={showPassword ? 'text' : 'password'} title='' onChange={event => {
                    setPassword(event.currentTarget.value);
                    setError('');
                  }
                  } />
                  <InputRightElement h={'full'}>
                    <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}
                  spacing={5}>
                  <Flex alignItems='center'>
                    <Switch id='remember' isChecked={rememberMe} onChange={event => setRememberMe(event.currentTarget.checked)}></Switch>
                    <Text pl={1}>Angemeldet bleiben</Text>
                  </Flex>
                  <Text color='blue.600'>
                    <Link onClick={_ => window.location.href = '/forgot'}>Passwort vergessen?</Link>
                  </Text>
                </Stack>
                <Button isLoading={isLoading} size="lg" bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }} onClick={event => handleSubmit(event)}>
                  Anmelden
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>

      <Show above='md'>
        <Flex minH={{ base: '50vh', lg: '70vh' }}>
          <Image
            maxW={{ base: '4xl' }}
            alt={'Login image'}
            objectFit={'scale-down'}
            src={OrgUtil.isDemo() ? '/images/org/demo/plant.webp' : '/images/org/cooperaid/learn.webp'}
            px={5}
          />
        </Flex>
      </Show>

    </Stack>
  );
}

export default Login