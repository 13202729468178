import { UsersListResponse } from "src/api/UsersListResponse";
import { UsersMeResponse } from "../api/UsersMeResponse";
import apiClient from "./core/ApiClient";
import { User } from "src/api/User";
import WebUser from "src/auth/WebUser";

const getMe = (token?: string): Promise<User> =>
  apiClient.get<UsersMeResponse>("/api/users/me", token).then(r => r.user);

const listAll = (): Promise<UsersListResponse> =>
  apiClient.get<UsersListResponse>("/api/users/");

const remove = (id: number): Promise<void> =>
  apiClient.delete<void>(`/api/users/${id}`);

const avatarUpload = (blob: any): Promise<void> => {
    // upload blob to server
    let formData = new FormData();
    formData.append('file', blob);
    return apiClient.upload('/api/users/avatar', formData).then(_ => Promise.resolve());
}

const avatarUrl = (user?: WebUser): string => {
  const userId = user?.data?.id;
  return userId ? apiClient.baseUrl + "/api/users/" + userId + "/avatar" : "";
};

export const userService = {
  getMe,
  listAll,
  remove,
  avatarUpload,
  avatarUrl
};
